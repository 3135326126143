import { useContext, useMemo, useState, TouchEvent, useRef, useEffect } from 'react'
import Card from 'components/schedule/card'
import { Oval } from 'react-loader-spinner'
import { ScheduleContext } from 'utils/context/ScheduleContext'
import './styles.css'
import downScroll from 'components/images/down_scroll.svg'

export interface EventData {
    id: number; 
    title: string;
    description: string; 
    speaker: string; 
    vinculo: string; 
    image: string; 
    timestamp: number;
    minutes: number; 
    registers: number; 
    active: boolean; 
    sala: {
        id: number; 
        title: string; 
        capacity: number; 
        minutes: number; 
    };
    sala_id: number; 
}


export type Day = "segunda" | "terça" | "quarta" | "quinta" | "sexta"

export interface ScheduleData {
    day: Day
    date: number
    events: EventData[]
}


const ScheduleCarousel = () => {
    const { scheduleData, loading, error } = useContext(ScheduleContext);
    const [startIndex, setStartIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [startSwapPos, setStartSwapPos] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    // Combina todos os eventos em uma lista única
    const allEvents = useMemo(() => {
        return scheduleData.flatMap((dayData) => dayData);
    }, [scheduleData]);

    // Lógica para avançar no carrossel, pulando 4 itens
    const goToNextEvent = () => {
        if (startIndex + itemsPerPage < allEvents.length) {
            setStartIndex((prevIndex) => prevIndex + itemsPerPage);
        }
    };

    // Lógica para voltar no carrossel, pulando 4 itens
    const goToPreviousEvent = () => {
        if (startIndex > 0) {
            setStartIndex((prevIndex) => prevIndex - itemsPerPage);
        }
    };

    useEffect(() => {
        const updateItemsPerPage = () => {
            setItemsPerPage(calculateItemsPerPage());
        };

        // Executa ao carregar e quando a janela é redimensionada
        updateItemsPerPage();
        window.addEventListener('resize', updateItemsPerPage);

        return () => {
            window.removeEventListener('resize', updateItemsPerPage);
        };
    }, []);

    const calculateItemsPerPage = () => {
        const width = window.innerWidth;

        if (width >= 1200) {
            return 4; // Desktop grande
        } else if (width >= 1000) {
            return 2; // Tablets grandes ou laptops pequenos
        } else if (width >= 768) {
            return 2; 
        } else {
            return 1; 
        }
    };

    const visibleEvents = allEvents.slice(startIndex, startIndex + itemsPerPage);

    const handleTouchStart = (e: TouchEvent) => {
        setStartSwapPos(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
        const endPos = e.changedTouches[0].clientX;
        const swipeDistance = startSwapPos - endPos;

        if (swipeDistance > 150) {
            goToNextEvent();
        } else if (swipeDistance < -150) {
            goToPreviousEvent();
        }
    };

    return loading ? (
        <Oval
            height={100}
            width={100}
            color="#460FE1"
            wrapperStyle={{ justifyContent: 'center', marginTop: '24px' }}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#F102AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />
    ) : error ? (
        <p className="error-message">Erro: Houve algum problema interno com nosso banco de dados. Será resolvido em breve! =(</p>
    ) : (
        <div className="schedule-items-container">
            <div className="schedule-items-container-carousel-container" style={{ justifyContent: 'flex-start' }}>
                <div className="schedule-items-container-carousel" onClick={goToPreviousEvent}>
                    <img src={downScroll} style={{ rotate: '90deg' }} alt="Flecha de scroll" />
                </div>
            </div>

            <div
                className="schedule-carousel-grid"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                style={{ position: 'relative' }}
                ref={ref}
            >
                {visibleEvents.length > 0 ? (
                    <div className="embla__viewport">
                        <div className="embra__container">
                        {visibleEvents.map((event:EventData, index:number) => {
                            return <Card {...event} key={`schedule-card-${startIndex + index}`} />;
                        })}
                        </div>
                    </div>
                ) : (
                    <div className="empty-box">
                        <img
                            width={200}
                            src="https://visualpharm.com/assets/981/Empty%20Box-595b40b65ba036ed117d43e8.svg"
                            alt="caixa vazia"
                        />
                        <p className="empty-schedule">A programação será divulgada em breve!</p>
                    </div>
                )}
            </div>

            <div className="schedule-items-container-carousel-container">
                <div className="schedule-items-container-carousel" onClick={goToNextEvent}>
                    <img src={downScroll} style={{ rotate: '275deg' }} alt="Flecha de scroll" />
                </div>
            </div>
        </div>
    );
}

export default ScheduleCarousel;
