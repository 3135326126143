import axios from "axios";

const API_URL = "https://api.secompufpe.com/";
const AUTH_URL = "https://auth.secompufpe.com/auth/";

interface RequestProps {
  [key: string]: string
}

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
      console.error(e)
    return e;
  }
};

class AuthService {
  login(props: RequestProps) {
    return axios
      .post(AUTH_URL + "login", props)
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  refreshAuth(refresh_token: string) {
    return axios.post(AUTH_URL + 'refresh', {
      refresh_token
    })
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(props: RequestProps) {
    return axios.post(AUTH_URL + 'register', { ...props });
  }

  subscribe(props: { idToken: string, id_palestra: number, email: string }) {
    return axios.post(
        API_URL + 'palestras/' + props.id_palestra + '/register', 
        { 
            usuario_email: props.email
        },
        {
            headers: {
                'Authorization': 'Bearer ' + props.idToken,
            }
        }
    );
}

  unsubscribe(props: { idToken: string, id_palestra: number}) {
    return axios.delete(API_URL + 'palestras/' + props.id_palestra + '/unregister', {
      headers: {
        'Authorization': 'Bearer ' + props.idToken
      },
    })
  }

  getCurrentUser() {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);

    return null;
  }

  subscribeEvent(props: { idToken: string | undefined, data: {[key: string]: string} }) {
    return axios.post(API_URL + 'palestras', props.data, {
      headers: {
        'Authorization': 'Bearer ' + props.idToken
      },
    })
  }

  subscribeEventImage(props: { idToken: string | undefined, eventID: string, file: File }) {
    return axios.post(API_URL + `palestras/${props.eventID}/imagelink`, {
      file_name: `${props.eventID}.${props.file.type.split('/')[1]}`,
      content_length: props.file.size
    }, {
      headers: {
        'Authorization': 'Bearer ' + props.idToken
      },
    })
  }

  uploadEventImage(props: { url: string, file: File, content_type: string }) {
    return axios.put(props.url, props.file, {
      headers: {
        'Content-Type': props.content_type
      }
    })
  }


  getEvents() {
    return axios.get(API_URL + 'palestras')
  }

  getSubscribedEvents(idToken: string) {
    return axios.get(API_URL + "palestras/inscricoes", {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

  }

  authVerify(auth: string) {
    const decodedJwt = parseJwt(auth)
    if (decodedJwt.exp * 1000 < Date.now()) {
      return false
    }
    return true
  }

  getUserInfo = async (idToken: string, refresh_token?: string) => {
    try {
      const response = await axios.get(API_URL + 'usuarios/me', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      return response;
    } catch (error:any) {
      if (error.response && error.response.status === 401) {
        if(refresh_token) {
          this.refreshAuth(refresh_token)
            .then(res => {
              const new_id_token = res.data.id_token
              const retrieveCurrentUser = JSON.parse(localStorage.getItem('user') || '')
              retrieveCurrentUser.id_token = new_id_token
              localStorage.setItem("user", JSON.stringify(retrieveCurrentUser))
            })
            .catch(() => this.logout())
        } else {
          this.logout(); 
        }
      }
      throw error;
    }
  };

  confirmAccount(props: { confirmation_code: string; username: string }) {
    return axios.post(AUTH_URL + 'confirm', props)
      .then(response => response.data)
      .catch(error => {
        console.error("Erro ao confirmar a conta:", error.response?.data || error.message);
        throw error;
      });
  }

  forgotPassword(props: {username:string}) {
    return axios.post( AUTH_URL +  'password/forgot' , props)
      .then(response => (response.data))
      .catch(error => {
        console.error("Erro ao enviar email para a conta:", error.response?.data || error.message);
        throw error;
      });
  }

  resetPassword(props: {confirmation_code: string; password: string; username:string}) {
    return axios.post( AUTH_URL + 'password/confirmforgot', props)
      .then(response => (response.data))
      .catch(error => {
        console.error("Erro ao resetar senha:", error.response?.data || error.message);
        throw error;
      });
  }

  checkinCPF(props:{cpf: string, idToken: string}){
    return axios.post(API_URL + 'checkin/cpf', { 
      cpf: props.cpf
  },
  {
      headers: {
          'Authorization': 'Bearer ' + props.idToken,
      }
  })
    .then(response => (response.data))
    .catch(error => {
      console.error("Erro ao fazer checkIn", error.response?.data || error.message);
      throw error;
    });
  }
  mcheckin(props:{idToken: string, usuario_email: string, palestra_id: number}){
    return axios.post(API_URL + 'palestras/mcheckin', {
      usuario_email: props.usuario_email,
      palestra_id: props.palestra_id
    },
    {
      headers: {
          'Authorization': 'Bearer ' + props.idToken,
      }
    })
    .then(response => (response.data))
    .catch(error => {
      console.error("Erro ao fazer checkIn", error.response?.data || error.message);
      throw error;
    });
  }
  vcheckin(props:{idToken: string, usuario_email: string}){
    return axios.post(API_URL + 'checkin/mvoluntario', {
      usuario_email: props.usuario_email
    },
    {
      headers: {
          'Authorization': 'Bearer ' + props.idToken,
      }
    })
    .then(response => (response.data))
    .catch(error => {
      console.error("Erro ao fazer checkIn", error.response?.data || error.message);
      throw error;
    });
  }

}

const Auth = new AuthService()

export default Auth
