import { ReactSVG } from "react-svg";
import project from 'utils/project.json'
import './styles.css';


interface Logo {
    page: string
    url: string
    title?: string
    group: Logo[]
    
}
interface SponsorshipSection {
    title: string,
    logos: Logo[]
}

function isValidURL(string: string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

const data: SponsorshipSection[] = JSON.parse(JSON.stringify(project)).sponsor
const Sponsorship = () => {
    return (
        <>
            <div className="sp-container" id='container-sp-section'>
            <div>
    {
        data.map((section: SponsorshipSection, sectionIndex) => (
            <div key={`section-${section.title}`} className="sp-section">
                <h3>{section.title}</h3>
                <div 
                    className={`sp-section-logos ${sectionIndex === 0 ? 'first-section' : ''}`}  // Adiciona 'first-section' à primeira seção
                >
                    {
                        section.logos.map((logo, index) => (
                            <a 
                                key={`section-${section.title}-${index}`} 
                                href={logo.page} 
                                style={{ width: "100%" }}
                            >
                                {logo.title && <h3 id="titulo-apoio" style={{marginTop:'-60px'}}>{logo.title}</h3>}
                                {
                                    logo.url ? 
                                    isValidURL(logo.url) ?
                                    <div className="sp-section-logo"><img src={logo.url} alt="logo de instituição membro da realização" /></div>
                                    :
                                    <ReactSVG key={`${section.title}-logo-${index}`} src={`icons/${logo.url}`} /> 
                                    : 
                                    <div key={`${section.title}-logo-${index}`} style={{display:'none'}} className="sp-section-empty-logo">You here</div>
                                }
                            </a>
                        ))
                    }
                </div>
            </div>
        ))
    }
</div>



            </div>
        </>
    );
}

export default Sponsorship;