import Checkin from 'components/checkin';
import ScheduleContextProvider from 'utils/context/ScheduleContext'

function CheckinPage() {

    
    return(
        <ScheduleContextProvider>
            <Checkin/>
        </ScheduleContextProvider>
    );
}

export default CheckinPage;