import VolunteerCheckin from "components/volunteer-checkin";
import ScheduleContextProvider from "utils/context/ScheduleContext";

function VCheckinPage(){


    return (
        
        <ScheduleContextProvider>
            
            <VolunteerCheckin/>
        </ScheduleContextProvider>
        
    )
}

export default VCheckinPage;