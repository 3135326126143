import { useContext, useEffect, useState } from 'react'
import { EventData } from '../'
import emptyPersonLogo from 'components/images/empty-person.png'
import { useNavigate } from 'react-router-dom'
import { ScheduleContext } from 'utils/context/ScheduleContext'
import { Day } from '../'
import './styles.css'
import './alert.css'

interface CardProps extends EventData {
    checkinUrlVersion?: 'path' | 'query';
  }
  


const Card = ({ id, title, minutes,  speaker, vinculo = 'Engenheira de Software na Softex', image = '', description = '', timestamp, sala, sala_id, registers, active, checkinUrlVersion = 'path',}: CardProps) => {
    const navigate = useNavigate()

    const typeMapping: { [key: string]: string } = {
        'Anfiteatro': 'Talk',
        'Grad 1': 'Minicurso',
        'Grad 2': 'Minicurso',
        'Grad 3': 'Minicurso',
        'Laboratório de Hardware': 'Minicurso',
        'Pitch': 'Talk',
    };

    const type = typeMapping[sala.title] || 'Outro';

    
    const escapedType = ['Abertura', 'GameDay', 'Encerramento'].includes(type)
    const { userInfo} = useContext(ScheduleContext)


    const handleCheckin = () => {
        if (active) {
          if (checkinUrlVersion === 'query') {
            navigate(`/palestras/checkin?palestra_id=${id}`);
          } else {
            navigate(`/palestras/${id}/checkin`);
          }
        } else {
          setError('Este evento não está ativo no momento.');
        }
      };


    const [subscribeButtomPos, setSubscribeButtomPos] = useState(-40)
    const [error, setError] = useState('')


    const formatTime = (date: Date) => {
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = date.getMinutes();
        if (minutes > 0) {
            return `${hours}h${("0" + minutes).slice(-2)}`;
        } else {
            return `${hours}h`;
        }
    }


    function converterParaDiaDaSemana(timestamp: number): Day {
        const days: Day[] = ["segunda", "terça", "quarta", "quinta", "sexta"];
    
        const data = new Date(timestamp * 1000); 
    
        const diaDaSemana = data.getDay();
    
        if (diaDaSemana === 0 || diaDaSemana === 6) {
            return "todos os dias";
        }
    
        return days[diaDaSemana - 1]; 
    }

    const dateBegin = new Date(timestamp * 1000);
    const hourBegin = formatTime(dateBegin);
  
    const durationInMillis = (sala.minutes) * 60 * 1000;
    const dateEnd = new Date(timestamp * 1000 + durationInMillis);
    const hourEnd = formatTime(dateEnd);
    const parsedImage = image && image.startsWith('.') ? `https://secomp.cin.ufpe.br/photos/${id}${image}` : image

    const speakerInfo = `${speaker}${vinculo ? `,\n ${vinculo}` : ''}`

    useEffect(() => {
        if (!error) return
        setTimeout(() => {
            setError('')
        }, 5000)
    }, [error])


    return (
        <div className="card" onMouseOver={() => setSubscribeButtomPos(0)} onMouseLeave={() => setSubscribeButtomPos(-40)}>
            {
                speaker || escapedType ? (
                    <div className="card-avatar">
                        <img className={`card-avatar-background ${parsedImage ? '' : 'avatar-empty-image'}`} src={parsedImage ? parsedImage : emptyPersonLogo} alt="foto do palestrante" />
                    </div>
                ) :
                null
            }
            <div className="card-container">
                <div className="card-type-container">
                    <p className={`card-type ${type === 'Minicurso' ? 'minicourse' : type === 'MostraCin' ? 'mostra-cin' : 'talk'}`} style={{ border: escapedType ? '1px solid #460FE1': '', background: escapedType ? '#460FE1': '', color: escapedType ? '#FDF8F5': '' }}>
                        {escapedType ? 'Evento' : type}
                    </p>
                    <p className="card-hour">
                        {converterParaDiaDaSemana(timestamp).charAt(0).toUpperCase() + converterParaDiaDaSemana(timestamp).slice(1)}, {hourBegin}
                        {!escapedType && ` - ${hourEnd}`}
                        <br />
                        {sala.title === 'Laboratório de Hardware' ? 'Lab de Hardware' : sala.title}
                    </p>

                </div>
                <div className="card-title" style={{ display: escapedType ? 'block' : '' }}>
                    {title}
                </div>
                <div className="card-text">
                    <p className="card-speaker-info">
                        {speakerInfo}
                    </p>
                    {userInfo?.is_admin ? (<span className="registers">{registers} inscritos</span>) : null}
                </div>
            </div>

            {
                vinculo !== 'Encerramento' ? 
                    vinculo === 'GameDay' ?
                    (
                        <>
                            <div onClick={() => window.open('/docs/aws.pdf', '_blank')} className="card-subscribe-mobile" style={{ bottom: '40px',backgroundColor: '#a256f8', color: '#FDF8F5'  }}>
                                Requisitos
                            </div>
                            <div onClick={() => window.open('/docs/aws.pdf', '_blank')} className="card-subscribe" style={{  bottom: subscribeButtomPos === 0 ? 40 : -40, backgroundColor: '#a256f8', color: '#FDF8F5'  }}>
                                Requisitos
                            </div>
                        
                            <div className="error-container" style={{ opacity: error ? '1' : '0' }}>
                                <p>{error}</p>
                            </div>
                        </>
                    )
                    : (
                        <>
                            {/* Botão de Check-in (Versão Mobile) */}
                            <div
                                onClick={handleCheckin}
                                className="card-subscribe-mobile"
                                style={{
                                backgroundColor: '#CCCCCC',
                                color: '#000000',
                                opacity: 1,
                                }}
                            >
                                Check-in
                            </div>

                            {/* Botão de Check-in (Versão Web) */}
                            <div
                                onClick={handleCheckin}
                                className="card-subscribe"
                                style={{
                                bottom: subscribeButtomPos,
                                backgroundColor: '#CCCCCC',
                                color: '#000000',
                                opacity: 1,
                                }}
                            >
                                Check-in
                            </div>
                            
                            <div className="error-container" style={{ opacity: error ? '1' : '0', zIndex:'20' }}>
                                <p>{error}</p>
                            </div>
                        </>
                    )
                : null
            }
        </div>
    );
}

export default Card;
