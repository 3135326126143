import HeaderSchedule from "components/header-schedule";
import { useEffect,useState } from "react";
import './styles.css'
import { useLocation } from 'react-router-dom';
import Auth from '../../utils/services/auth.service';

function ConfirmPage() {
    const [status, setStatus] = useState('Aguardando confirmação...');
    const query = new URLSearchParams(useLocation().search);
    const confirmationCode = query.get('confirmation_code');
    const username = query.get('username');
    const [color, setColor] = useState('#FDF8F5')

    useEffect(() => {
      const confirmAccount = async () => {
        if (confirmationCode && username) {
          try {
            await Auth.confirmAccount({ confirmation_code: confirmationCode, username });
            setStatus('Conta confirmada com sucesso!');
            setColor('#006400')
          } catch (error) {
            setStatus('Erro ao confirmar a conta');
            setColor('#FF0000')
          }
        } else {
          setStatus('Código de confirmação ou usuário não fornecido.');
          setColor('#FDF8F5')
        }
      };
  
      confirmAccount();
    }, [confirmationCode, username]);
    
    return(
        <>
            <HeaderSchedule/>
            <div className="body-content confirm-message-container">
                <div className="status-container" style={{color:color}} >
                        <p>{status}</p>
                </div>
            </div>
            <div className='container-copyright-schedule' style={{minHeight:'0'}}>
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
        </>
    );
}


export default ConfirmPage;