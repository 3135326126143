import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import ScheduleContextProvider, { ScheduleContext } from "utils/context/ScheduleContext";
import './style.css';
import { useFormik } from "formik";
import AuthService from 'utils/services/auth.service';
import LoadingComponent from "components/loading";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Html5Qrcode } from "html5-qrcode";
import HeaderSchedule from "components/header-schedule";

const allowedUsers = ["glr2@cin.ufpe.br", "ebpj@cin.ufpe.br", "avss2@cin.ufpe.br", "bfpc@cin.ufpe.br", 
    "bfl3@cin.ufpe.br", "chgs3@cin.ufpe.br", "jaas@cin.ufpe.br", "maos3@cin.ufpe.br", "mgbl@cin.ufpe.br",
    "vlpl@cin.ufpe.br", "mcabral@valcann.com.br", "jvmb2@cin.ufpe.br"
]

const validate = (values: any) => {
  const errors: any = {};
  if (!values.user_id) {
    errors.user_id = 'Campo obrigatório';
  }
  return errors;
};

function checkUser(user_email:string){
    if(allowedUsers.includes(user_email)){
        return true
    }
    return false
}

function VolunteerCheckin() {
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(ScheduleContext);

  const user = AuthService.getCurrentUser();

  const [showQrReader, setShowQrReader] = useState(false);

  const html5QrcodeScannerRef = useRef<Html5Qrcode | null>(null);

  // Referência para controlar as leituras
  const hasScannedRef = useRef(false);
  const isStoppingRef = useRef(false); // Indica se o scanner está em processo de parar

  // Função para parar o scanner, memoizada com useCallback
  const stopScanner = useCallback(() => {
    if (html5QrcodeScannerRef.current && !isStoppingRef.current) {
      isStoppingRef.current = true; // Marca que o scanner está sendo parado

      html5QrcodeScannerRef.current
        .stop()
        .then(() => {
          html5QrcodeScannerRef.current = null;
          hasScannedRef.current = false; // Reinicia o flag
        })
        .catch((err: any) => {
          console.error("Erro ao parar o leitor de QR Code", err);
        })
        .finally(() => {
          isStoppingRef.current = false; // Reseta a flag após a operação
        });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      user_id: ''
    },
    validate,
    onSubmit: (values) => {
      const submitValues = {
        user_id: values.user_id
      };
      doSubscribe(submitValues);
    },
  });

  // Função para gerenciar o feedback, memoizada com useCallback
  const handleFeedback = useCallback((response: any, error: any = null) => {
    if (error) {
      if (error.response?.status === 401) {
        toast.error("Login expirado!", {
          position: "top-center",
          theme: 'dark',
        });
      } else if (error.response?.data.message === 'Usuário não encontrado') {
        toast.error("Usuário não foi encontrado!", {
          position: "top-center",
          theme: 'dark',
        });
      } else if (error.response?.data.error) {
        toast.error(error.response?.data.error, {
          position: "top-center",
          theme: 'dark',
        });
      } else {
        toast.error(
          "Ocorreu um erro com o cadastro do voluntário. Tente novamente mais tarde!",
          {
            position: "top-center",
            theme: 'dark',
          }
        );
      }
      console.log(error);
    } else {
      toast.success("Check-in realizado com sucesso!", {
        position: "top-center",
        theme: 'dark',
      });
    }
  }, []);

  // Função para realizar o check-in manual, memoizada com useCallback
  const doSubscribe = useCallback((values: any) => {
    setIsLoading(true);

    AuthService.vcheckin({
      idToken: user.id_token,
      usuario_email: values.user_id
    })
      .then((response) => {
        handleFeedback(response);
        formik.setFieldValue("user_id", '');
      })
      .catch((err) => {
        handleFeedback(null, err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user.id_token, handleFeedback, formik]);

  // Função para lidar com a leitura do QR Code, memoizada com useCallback
  const handleBarCodeScanned = useCallback(
    async (data: string) => {
      if (data) {

        try {
          const response = await fetch(`https://api.secompufpe.com/checkin/voluntario`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.id_token}`,
            },
            body: JSON.stringify({ usuario_email: data }),
          });

          const responseJson = await response.json();

          if (response.ok) {
            handleFeedback(responseJson);
          } else {
            const error = {
              response: {
                status: response.status,
                data: responseJson,
              },
            };
            handleFeedback(null, error);
          }
        } catch (error) {
          toast.error('Erro ao registrar o voluntário.', {
            position: "top-center",
            theme: 'dark',
          });
          console.error(error);
        } finally {

          // Aguarda 2 segundos antes de permitir uma nova leitura
          setTimeout(() => {
            hasScannedRef.current = false; // Permite uma nova leitura
          }, 4000); // 2 segundos
        }
      }
    },
    [user.id_token, handleFeedback]
  );

  // useEffect para inicializar o leitor de QR Code quando showQrReader é true
  useEffect(() => {
    if (showQrReader && !html5QrcodeScannerRef.current) {
      const html5QrCode = new Html5Qrcode("reader");
      html5QrcodeScannerRef.current = html5QrCode;

      const config = {
        fps: 10,
        qrbox: {
          width: 300, // Defina a largura desejada
          height: 300, // Defina a altura desejada
        },
      };

      html5QrCode
        .start(
          { facingMode: "environment" },
          config,
          (decodedText: string) => {
            if (!hasScannedRef.current) {
                hasScannedRef.current = true;
                handleBarCodeScanned(decodedText);
            }
            
          },
          (errorMessage: string) => {
            console.log("Erro na leitura do QR Code:", errorMessage);
          }
        )
        .catch((err: any) => {
          console.error("Erro ao iniciar o leitor de QR Code", err);
          toast.error("Erro ao acessar a câmera.", {
            position: "top-center",
            theme: "dark",
          });
          setShowQrReader(false);
        });
    }

    // Limpar o leitor de QR Code quando showQrReader for false
    return () => {
      stopScanner();
    };
  }, [showQrReader, handleBarCodeScanned, stopScanner]);

  // Função para iniciar o leitor de QR Code, memoizada com useCallback
  const startQrCodeScanner = useCallback(() => {
    hasScannedRef.current = false; // Reinicia o flag ao iniciar o scanner
    setShowQrReader(true);
  }, []);

  return (
    <>
      <ScheduleContextProvider>
        {(userInfo?.is_admin && checkUser(userInfo.email)) ? showQrReader ?
        <div className="qr-reader-overlay">

        <div id="reader" className="qr-reader"></div>
        <button
          type='button'
          style={{
            marginTop: '1rem'
          }}
          className="close-button"
          onClick={() => {
            setShowQrReader(false);
            stopScanner();
          }}
        >
          Fechar
        </button>

    </div>
        
        : (
          <><HeaderSchedule/>
          <div className="manualCheckinContainer">
            <div className="doCheckin">
              <h1>
                Check-in de Voluntários
              </h1>
              <form className="formContainer" onSubmit={formik.handleSubmit}>
                <div className='nome-container'>
                  <label id="id-label">ID do Voluntário *</label>
                  <input
                    name='user_id'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.user_id}
                  />
                  {
                    formik.touched.user_id && formik.errors.user_id
                      ? <span className="error">{formik.errors.user_id}</span>
                      : null
                  }
                </div>
                <div className="button-container">
                  {
                    isLoading ? <LoadingComponent style={{ margin: 0 }} /> :
                      (
                        <>
                          <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='filled-button'>Fazer Check-in</button>
                          <button type='button' className='filled-button' style={{marginTop: "1rem"}} onClick={startQrCodeScanner}>Ler QR Code</button>
                        </>
                      )
                  }
                </div>
              </form>
              
            </div>
          </div>

          </>
        ) : null}
        <ToastContainer />
      </ScheduleContextProvider>
    </>
  );
}

export default VolunteerCheckin;
