import { useEffect, useState  } from "react"
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import AuthService from 'utils/services/auth.service'
import './styles.css'
import HeaderSchedule from "components/header-schedule"
import loginImage from 'components/images/login-img.svg'

const validate = (values: any) => {
    const errors: any = {};

    if (!values.username) {
        errors.username = 'Campo obrigatório';
    }
    if (!values.password) {
        errors.password = 'Campo obrigatório';
    }

    return errors;
};

function Login() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [loginError, setLoginError] = useState('')

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            remember: false,
        },
        validate,
        onSubmit: (values) => {
            doLogin(values);
        },
    });
    
    const doLogin = (values: any) => {
        setIsLoading(true)
        AuthService
            .login(values)
            .then(() => {
                setIsLoading(false)
                navigate('/cronograma')
            })
            .catch((err) => {
                if (err.response?.data.message === "username or password is invalid.") {
                    setLoginError("O usuário ou senha informados estão incorretos.")
                } else if(err.response?.data.message === "User not confirmed."){
                    setLoginError("E-mail não confirmado! Verifique sua caixa de entrada e tente novamente.")
                }else {
                    setLoginError('Ocorreu um erro com o login, tente novamente mais tarde.')
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const user = AuthService.getCurrentUser()
        if(user && user.id_token) {
            navigate('/cronograma')
        }
    }, [navigate])
    
    return (
        <>
            <HeaderSchedule />
            <div className="body-content main-container">
                {isLoading ? (
                    <div className="Loginform-loading">
                        <Oval
                            height={100}
                            width={100}
                            color="#460FE1"
                            wrapperStyle={{ justifyContent: "center", marginTop: "24px" }}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#F102AE"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                        <p>Carregando...</p>
                    </div>
                ) : (
                    <>
                        <div className="img-login">
                            <img src={loginImage} alt="Imagem de evento organizado pelo grupo PET Informática" />
                        </div>
    
                        <div className="LoginForm">
                            <form onSubmit={formik.handleSubmit}>
                                <div>
                                    <h1>Login</h1>
                                    <h2>Preencha os campos abaixo com seus dados de acesso</h2>
                                </div>
                                <div className="inputs-container">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        placeholder="pessoa@gmail.com"
                                        name="username"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.username}
                                    />
                                </div>
    
                                <div className="inputs-container">
                                    <label>Senha</label>
                                    <input
                                        name="password"
                                        type="password"
                                        placeholder="********"
                                        className="input-field"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                </div>

                                {
                                    loginError
                                        ? <div className='login-error'>
                                            {loginError}
                                        </div>
                                        : null
                                }
    
                                <div className="texto-container">
                                    <Link to={"/forgot_password"}>
                                        <p>Esqueci minha senha</p>
                                    </Link>
    
                                    <p>
                                        <Link to={"/Cadastro"}>
                                           Não possui conta?{" "} Faça aqui
                                        </Link>
                                    </p>
                                </div>

    
                                <div className="containerFinal">
                                    <div className="divLembra">
                                    <label className="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="remember"
                                            checked={formik.values.remember} 
                                            onChange={formik.handleChange}  
                                        />
                                        Lembre de mim
                                    </label>

                                    </div>
                                    <div className='button-container'>
                                    <button type="submit">Login</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </>
                )}
                
            </div>
            <div className='container-copyright-schedule' style={{minHeight:'0'}}>
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
            
        </>
    );
    
}

export default Login;