import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScheduleContextProvider, { ScheduleContext } from "utils/context/ScheduleContext";
import './style.css'

interface totalUsers{
    total_usuarios: string;
}

interface totalUWC{
    total_usuarios_com_checkin: string;
}

function EventInfo() {
    const navigate = useNavigate()
    const [totalInscritos, setTotalInscritos] = useState<totalUsers>()
    const [totalComCheckin, setTotalComCheckin] = useState<totalUWC>()
    const {currentUser, userInfo} = useContext(ScheduleContext)
    
    useEffect(() => {
        const fetchInsc = async (token: string) => {
            try {
                const response = await axios.get('https://api.secompufpe.com/usuarios/total', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTotalInscritos(response.data);
            } catch (error) {
                console.error('Erro ao buscar total de inscritos:', error);
            }
        };

        const fetchCheckins = async (token: string) => {
            try {
                const response = await axios.get('https://api.secompufpe.com/usuarios/with_checkin', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTotalComCheckin(response.data);
            } catch (error) {
                console.error('Erro ao buscar total de usuários com check-in:', error);
            }
        };

        if(currentUser != null){
            if (currentUser.id_token) {
                fetchInsc(currentUser.id_token);
                fetchCheckins(currentUser.id_token);
            } else {
                navigate('/login')
            }
        }

    }, [currentUser, navigate]);

    return (
        <>
        <ScheduleContextProvider>
                {userInfo?.is_admin ? (<div className='total-insc'>                    
                    <span>Total de Inscritos: {totalInscritos?.total_usuarios}</span>
                    <br />
                    <span>Total Com Check-in: {totalComCheckin?.total_usuarios_com_checkin}</span>
                </div>) : null}
        </ScheduleContextProvider>
        </>
    )
    }

    export default EventInfo;