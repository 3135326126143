import ScheduleContextProvider from 'utils/context/ScheduleContext'
import Palestra from 'components/palestra'

function PalestraPage() {
    return(
        <ScheduleContextProvider>
            <Palestra/>
        </ScheduleContextProvider>
    );
}

export default PalestraPage;