import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LandingPage from './pages/landing-page';
import SubscribePage from './pages/subscribe';
import LoginPage from 'pages/login';
import PalestraPage from 'pages/palestra';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SchedulePage from 'pages/schedule';
import ConfirmPage from 'pages/confirm-acc';
import ForgotPassword from 'pages/forgot-pass';
import ResetPassword from 'pages/reset-password';
import CheckinPage from 'pages/checkin';
import PalestraCheckinPage from 'pages/palestra/checkin';
import MCheckinPage from 'pages/mcheckin';
import VCheckinPage from 'pages/vcheckin';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/cadastro",
    element: <SubscribePage />,
  },
  {
    path:"/login",
    element: <LoginPage />
  },
  {
    path: "/cronograma",
    element: <SchedulePage />
  },
  {
    path:"/mail_confirmation",
    element: <ConfirmPage />
  },
  {
    path: "/forgot_password",
    element: <ForgotPassword />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path:"/palestra",
    element: <PalestraPage />
  },
  {
    path:"/palestras", 
    element: <PalestraCheckinPage />
  },
  {
    path:"checkin",
    element: <CheckinPage />
  },
  {
    path:"palestras/checkin",
    element: <MCheckinPage />
  },
  {
    path:"voluntario/checkin",
    element: <VCheckinPage />
  }

])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
