import HeaderSchedule from "components/header-schedule";
import { useState } from "react";
import './styles.css';
import Auth from '../../utils/services/auth.service';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState({ text: '', color: '' });

    const isValidEmail = (email:string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleForgotPassword = async () => {
        if (!isValidEmail(email)) {
            setMessage({
                text: 'Por favor, insira um endereço de email válido.',
                color: '#FF0000'
            });
            return;
        }

        try {
            await Auth.forgotPassword({username : email});
            setMessage({
                text: 'Um email foi enviado para sua conta caso ela já tenha sido cadastrada',
                color: '#006400' 
            });
        } catch (error) {
            setMessage({
                text: 'Um erro ocorreu ao enviar o email',
                color: '#FF0000' 
            });
        }
    };

    return (
        <>
            <HeaderSchedule />
            <div className="body-content forgot-password-container">
                <div className="forgot-container">
                    <h1>Insira o seu email e enviaremos um link para você voltar a acessar a sua conta.</h1>
                    <div className="email-field-container">
                        <label htmlFor="email" className="email-label">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Digite seu email cadastrado na Secomp"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="forgot-input"
                        />
                    </div>
                    {
                    message.text && (
                        <p style={{ color: message.color, fontWeight:'bold', fontSize:'15px' }}>{message.text}</p>
                    )
                    }
                    <button onClick={handleForgotPassword}>Recuperar Senha</button>
                </div>
            </div>
            <div className='container-copyright-schedule' style={{minHeight:'0'}}>
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
        </>
    );
}

export default ForgotPassword;
