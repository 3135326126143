import HeaderSchedule from "components/header-schedule";
import { useContext, useState } from "react";
import Auth from '../../utils/services/auth.service';
import { testCPF } from "utils/utils";
import { ScheduleContext } from "utils/context/ScheduleContext";

function Checkin() {
    const {currentUser, userInfo} = useContext(ScheduleContext)
    const [cpf, setcpf] = useState('');
    const [message, setMessage] = useState({ text: '', color: '' });

    const handlecheckin = async () => {
        if (!testCPF(cpf)) {
            setMessage({
                text: 'Por favor, insira um CPF válido.',
                color: '#FF0000'
            });
            return;
        }
        if (currentUser && userInfo?.is_admin){
            try {
                await Auth.checkinCPF({
                    cpf: cpf, idToken: currentUser.id_token
                });
                setMessage({ text: 'Checkin realizado com sucesso!', color: 'green' });
                setcpf('');
            } catch (error:any) {
                if (error.response && error.response.status === 404) {
                    setMessage({
                        text: 'CPF não encontrado. Por favor, verifique se o participante está cadastrado na secomp.',
                        color: 'red'
                    });
                } else {
                    const errorMessage = 'Erro ao fazer checkin'
                    setMessage({ text: errorMessage, color: 'red' });
                }
            }
        } else {
                 setMessage({
                     text: 'Precisa estar logado para conseguir fazer checkin',
                     color: '#FF0000' 
                     })
        }

    };

    return (
        <>
            <HeaderSchedule />
            <div className="body-content forgot-password-container">
                <div className="forgot-container">
                    <h1>Insira o CPF do participante.</h1>
                    <div className="email-field-container">
                        <label htmlFor="email" className="email-label">CPF</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Digite o cpf do participante(Apenas números)"
                            value={cpf}
                            onChange={(e) => setcpf(e.target.value)}
                            className="forgot-input"
                        />
                    </div>
                    {
                    message.text && (
                        <p style={{ color: message.color, fontWeight:'bold', fontSize:'15px' }}>{message.text}</p>
                    )
                    }
                    <button onClick={handlecheckin}>Fazer Checkin</button>
                </div>
            </div>
            <div className='container-copyright-schedule'>
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
        </>
    );
}

export default Checkin;
