import CheckinScheduleSection from 'components/checkin/schedule-section';
import EventInfo from 'components/eventInfo';
import HeaderSchedule from 'components/header-schedule';
import ScheduleContextProvider from 'utils/context/ScheduleContext';

function PalestraCheckinPage() {

    return (
        <ScheduleContextProvider>
            <HeaderSchedule/>
            <div className="Schedule">
                <h1 className="Schedule-title">Cronograma</h1>
                <EventInfo/>                
                <CheckinScheduleSection />
            </div>
            <div className='container-copyright-schedule' style={{minHeight:'0'}}>
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
      </ScheduleContextProvider>
    );
}

export default PalestraCheckinPage;