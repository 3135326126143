import React, { useState } from 'react';
import './styles.css'

interface FilterModalProps {
    eventTypes: string[];
    selectedTypes: string[];
    handleTypeChange: (type: string) => void;
    timeFilters: string[];
    selectedTimeFilters: string[];
    handleTimeFilterChange: (filter: string) => void;
    selectedStatusFilters: string[];
    handleStatusFilterChange: (status: string) => void;
    hidePastEvents: boolean;
    setHidePastEvents: React.Dispatch<React.SetStateAction<boolean>>;
}


const FilterModal: React.FC<FilterModalProps> = ({
    eventTypes,
    selectedTypes,
    handleTypeChange,
    timeFilters,
    selectedTimeFilters,
    handleTimeFilterChange,
    selectedStatusFilters,
    handleStatusFilterChange,
    hidePastEvents,
    setHidePastEvents,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button onClick={() => setIsOpen(true)} className="open-modal-btn"></button>

            {isOpen && (
                <div className="modal-overlay" onClick={() => setIsOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => setIsOpen(false)} className="close-modal-btn">×</button>
                        <h2>Filtros</h2>

                        <div className="filter-group">
                            <h3>Tipo</h3>
                            {eventTypes.map((type, index) => (
                                <label key={index} className="filter-option">
                                    <input
                                        type="checkbox"
                                        checked={selectedTypes.includes(type)}
                                        onChange={() => handleTypeChange(type)}
                                    />
                                    {type}
                                </label>
                            ))}
                        </div>

                        <div className="filter-group">
                            <h3>Horário</h3>
                            {timeFilters.map((filter, index) => (
                                <label key={index} className="filter-option">
                                    <input
                                        type="checkbox"
                                        checked={selectedTimeFilters.includes(filter)}
                                        onChange={() => handleTimeFilterChange(filter)}
                                    />
                                    {filter}
                                </label>
                            ))}
                        </div>


                        <div className="filter-group">
                            <h3>Status</h3>
                            <label className="filter-option">
                                <input
                                    type="checkbox"
                                    checked={selectedStatusFilters.includes("Disponivel")}
                                    onChange={() => handleStatusFilterChange("Disponivel")}
                                />
                                Disponível
                            </label>
                            <label className="filter-option">
                                <input
                                    type="checkbox"
                                    checked={selectedStatusFilters.includes("Esgotado")}
                                    onChange={() => handleStatusFilterChange("Esgotado")}
                                />
                                Esgotado
                            </label>
                            <label className="filter-option">
                                <input
                                    type="checkbox"
                                    checked={selectedStatusFilters.includes("Inscrito")}
                                    onChange={() => handleStatusFilterChange("Inscrito")}
                                />
                                Inscrito
                            </label>
                            <label className="filter-option">
                                <input
                                    type="checkbox"
                                    checked={selectedStatusFilters.includes("Lista de Espera")}
                                    onChange={() => handleStatusFilterChange("Lista de Espera")}
                                />
                                Lista de Espera
                            </label>
                        </div>
                        <div className="filter-group">
                        <h3>Outros</h3>
                        <label className="filter-option">
                            <input
                            type="checkbox"
                            checked={hidePastEvents}
                            onChange={() => setHidePastEvents((prev) => !prev)}
                            />
                            Ocultar eventos antigos
                        </label>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FilterModal;
