import AuthService from 'utils/services/auth.service'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import { useFormik } from 'formik'
import { testCPF } from '../../utils/utils'
import './styles.css'
import HeaderSchedule from 'components/header-schedule'
import InscricaoImg from 'components/images/img-inscricao.svg'
import { Link } from 'react-router-dom'
import LoadingComponent from 'components/loading'

const isPasswordValid = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;

    return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && isLongEnough;
};

const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
        errors.name = 'Campo obrigatório';
    }

    if (!values.family_name) {
        errors.family_name = 'Campo obrigatório';
    }

    if (!values.email) {
        errors.email = 'Campo obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Formato de email inválido';
    }

    if (!values.emailValidation) {
        errors.emailValidation = 'Campo obrigatório'
    } else if (values.email !== values.emailValidation) {
        errors.emailValidation = 'Os emails não conferem'
    }

    if (!values.cpf) {
        errors.cpf = 'Campo obrigatório';
    } else if (values.cpf.length < 11 || !testCPF(values.cpf)) {
        errors.cpf = 'CPF Inválido'
    }

    if (!values.vinculo || values.vinculo === 'empty') {
        errors.vinculo = 'Campo obrigatório';
    }

    if (!values.genero || values.genero === 'empty') {
        errors.genero = 'Campo obrigatório';
    }

    if (!values.password) {
        errors.password = 'Campo obrigatório'
    } else if (!isPasswordValid(values.password)) {
        errors.password = 'A senha deve conter letra maiúscula, minúscula, número, caractere especial e mais de 8 dígitos.'
    }

    if (!values.terms) {
        errors.terms = 'Você precisa aceitar os termos para continuar.'
    }

    return errors;
};

function Subscribe() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(false)
    const [subscriptionError, setSubscriptionError] = useState('')
    const isEnd = false

    const formik = useFormik({
        initialValues: {
            email: '',
            emailValidation: '',
            cpf: '',
            vinculo: '',
            name: '',
            family_name: '',
            genero: '',
            password: '',
            promotional: false,
            terms: false
        },
        validate,
        onSubmit: (values) => {
            const submitValues = {
                username: values.email,
                cpf: values.cpf,
                name: values.name,
                family_name: values.family_name,
                vinculo: values.vinculo,
                genero: values.genero,
                password: values.password,
                promotional: values.promotional
            };
            doSubscribe(submitValues);
        },
    });

    const doSubscribe = (values: any) => {
        setIsLoading(true)
        if (isEnd) {
            setSubscriptionError('O Evento já foi encerrado!')
            setIsLoading(false)
            return
        }
        AuthService.register(values)
            .then((e) => {
                setIsSubscribed(true)
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response?.data.message === "requisição falhou com status: 500") {
                    setSubscriptionError('Email e/ou cpf já existe!')
                } else {
                    setSubscriptionError('Ocorreu um erro com a inscrição, tente novamente mais tarde.')
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!isSubscribed) return
        const interval = setInterval(() => {
            navigate('/login')
        }, 8000);

        return () => clearInterval(interval)
    }, [navigate, isSubscribed])


    return (
        <div className='subscribe-page'>
            <HeaderSchedule backgroundColor="transparent" />
            {isLoading ? <LoadingComponent style={{ height: 'calc(100vh - 150px)', margin: 0 }} />
                : isSubscribed ? (
                    <div className='redirecting'>
                        <div className='register-successful-title inter bold'>
                            Obrigado pelo cadastro na SECOMP CIN-UFPE 2024! <br /><br />
                            O <span className="register-successful-highlight"> e-mail de confirmação</span> para o seu cadastro foi enviado! Confirme antes de realizar o login.
                        </div>
                        <div className="register-successful-container">
                            <Oval
                                height={36}
                                width={36}
                                color="#460FE1"
                                wrapperStyle={{ justifyContent: 'center', marginTop: '24px' }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#460FE1"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                            <p className="register-successful-return-message">Retornando para o Login</p>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='subscribeContainer'>
                            <div className='img-sub'>
                                <img src={InscricaoImg} alt="Imagem de evento organizado pelo grupo PET Informática" />
                            </div>
                            <div className='Subscribeform'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='introduction'>
                                        <h1 style={{ fontSize: '32px' }}>Cadastro</h1>
                                        <h2>Preencha seus dados para acessar a plataforma</h2>
                                    </div>

                                    <div>
                                        <div className="relative-box" style={{ marginTop: '10px' }}>
                                            <label style={{ marginBottom: '6px' }}>Nome</label>
                                            <input
                                                type="text"
                                                name='name'
                                                placeholder="Digite seu nome aqui"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                style={{
                                                    width: '100%'
                                                }}
                                            />
                                            {
                                                formik.touched.name && formik.errors.name
                                                    ? <p style={{ color: 'red', fontSize: '0.8rem', marginTop: '4px' }}>{formik.errors.name}</p>
                                                    : null
                                            }
                                        </div>
                                        <div className="relative-box" style={{ marginTop: '10px' }}>
                                            <label style={{ marginBottom: '6px' }}>Sobrenome</label>
                                            <input
                                                type="text"
                                                name='family_name'
                                                placeholder="Digite seu nome aqui"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.family_name}
                                                style={{
                                                    width: '100%'
                                                }}
                                            />
                                            {
                                                formik.touched.family_name && formik.errors.family_name
                                                    ? <p style={{ color: 'red', fontSize: '0.8rem', marginTop: '4px' }}>{formik.errors.family_name}</p>
                                                    : null
                                            }
                                        </div>

                                        <div className='container-inscricao'>
                                            <div className="relative-box">
                                                <label style={{ marginBottom: '6px' }}>Gênero</label>
                                                <select
                                                    name="genero"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.genero}
                                                >
                                                    <option value=""></option>
                                                    <option value="mulher-cis">Mulher Cisgênero</option>
                                                    <option value="mulher-trans">Mulher Transgênero</option>
                                                    <option value="homem-cis">Homem Cisgênero</option>
                                                    <option value="homem-trans">Homem Transgênero</option>
                                                    <option value="pessoa-nb">Pessoa Não-Binária</option>
                                                    <option value="pessoa-agen">Pessoa Agênera</option>
                                                    <option value="genero-fluido">Gênero-fluido</option>
                                                    <option value="vazio">Prefiro não informar</option>
                                                </select>
                                                {
                                                    formik.touched.genero && formik.errors.genero
                                                        ? <p className="error-info">{formik.errors.genero}</p>
                                                        : null
                                                }
                                            </div>
                                            <div className="relative-box">
                                                <label style={{ marginBottom: '6px' }}>CPF</label>
                                                <input
                                                    type="text"
                                                    name='cpf'
                                                    placeholder="123.456.789-01"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.cpf}
                                                    maxLength={11}
                                                />
                                                {
                                                    formik.touched.cpf && formik.errors.cpf
                                                        ? <p className="error-info">{formik.errors.cpf}</p>
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        <div className='container-inscricao' >

                                            <div className="relative-box">
                                                <label style={{ marginBottom: '6px' }}>Vínculo</label>
                                                <select
                                                    name="vinculo"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.vinculo}
                                                >
                                                    <option value=""></option>
                                                    <option value="Valcann">Valcann</option>
                                                    <option value="Beyond Co">Beyond Co</option>
                                                    <option value="Neurotech">Neurotech</option>
                                                    <option value="EDS">EDS</option>
                                                    <option value="EDX">EDX</option>
                                                    <option value="Incognia">Incognia</option>
                                                    <option value="Loomi">Loomi</option>
                                                    <option value="Vinta Software">Vinta Software</option>
                                                    <option value="RoboCIn">RoboCIn</option>
                                                    <option value="CIn-UFPE">CIn-UFPE</option>
                                                    <option value="UFPE">UFPE</option>
                                                    <option value="Outros">Outros</option>
                                                </select>
                                                {
                                                    formik.touched.vinculo && formik.errors.vinculo
                                                        ? <p className="error-info">{formik.errors.vinculo}</p>
                                                        : null
                                                }
                                            </div>
                                            <div className="relative-box">
                                                <label style={{ marginBottom: '6px' }}>Email</label>
                                                <input
                                                    type="email"
                                                    name='email' placeholder="pessoa@gmail.com"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                {
                                                    formik.touched.email && formik.errors.email
                                                        ? <p className="error-info">{formik.errors.email}</p>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className='container-inscricao' >
                                            <div className="relative-box">
                                                <label style={{ marginBottom: '6px' }}>Senha</label>
                                                <input
                                                    type="password"
                                                    name='password'
                                                    placeholder="********"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                {
                                                    formik.touched.password && formik.errors.password
                                                        ? <p className="error-info">{formik.errors.password}</p>
                                                        : null
                                                }
                                            </div>
                                            <div className="relative-box">
                                                <label style={{ marginBottom: '6px' }}>Confirme seu email</label>
                                                <input
                                                    name='emailValidation'
                                                    placeholder='confirme seu email'
                                                    type='email'
                                                    autoComplete="new-password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.emailValidation}
                                                />
                                                {
                                                    formik.touched.emailValidation && formik.errors.emailValidation
                                                        ? <p className="error-info">{formik.errors.emailValidation}</p>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            subscriptionError
                                                ? <div className='subs-error'>
                                                    {subscriptionError}
                                                </div>
                                                : null
                                        }
                                    </div>

                                    <div className='checkbox'>
                                        <label className='custom-checkbox-2' htmlFor="">
                                            <input 
                                                type="checkbox" 
                                                checked={formik.values.promotional}
                                                onChange={(e) => formik.setFieldValue('promotional', e.target.checked)}
                                            /> Gostaria de receber e-mails da Secomp UFPE e seus patrocinadores.
                                        </label>
                                        <label className='custom-checkbox-2' htmlFor="">
                                            <input
                                                id=""
                                                type="checkbox"
                                                checked={formik.values.terms}
                                                onChange={(e) => formik.setFieldValue('terms', e.target.checked)}
                                            /> Ao me cadastrar declaro ter lido e aceito os <a href="https://drive.google.com/file/d/15BP6eVASKrJJ_2GZ7PsD-D03n6qMkkF0/view?usp=sharing">termos de uso da plataforma de inscrições</a>  da SECOMP 2024.
                                        </label>
                                    </div>
                                    <div className="containerFinal">
                                        <div className="divLembra">
                                            <Link to={'/login'}>
                                                <p style={{ fontSize: '14px', margin: 'auto auto', fontWeight: 500 }}>Já possuo cadastro</p>
                                            </Link>
                                        </div>
                                        <div className='button-container'>
                                            <button type='submit' disabled={!formik.isValid} className='filled-button'>Cadastrar</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </>
                )}
            <div className="container-copyright-schedule subscribe-footer">
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
        </div>
    )
}

export default Subscribe;
