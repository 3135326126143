
import ManualCheckin from "components/manual-checkin";
import ScheduleContextProvider from "utils/context/ScheduleContext";

function MCheckinPage(){


    return (
        
        <ScheduleContextProvider>
            
            <ManualCheckin/>
        </ScheduleContextProvider>
        
    )
}

export default MCheckinPage;