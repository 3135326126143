import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

import { CSSProperties } from "react"

export type LoadingSize = 'sm' | 'md' | 'lg'

const setSize: (size: LoadingSize) => string = (size: LoadingSize = 'lg') => {
  let height = '300px'
  if(size === 'sm') {
    height = 'auto'
  } else if (size === 'md') {
    height = '150px'
  } else if (size === 'lg') {
    height = '300px'
  }
  return height
}

const CircularProgressWithLabel = ({ progress }: { progress: number }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={progress} sx={{ width: '56px !important', height: '56px !important' }} />
      <div
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(progress)}%`}</Typography>
      </div>
    </div>
  );
}

interface LoadingComponentProps {
  type?: 'progress' | 'continuous'
  progress?: number
  text?: string
  size?: LoadingSize
  style?: CSSProperties
}

const LoadingComponent = ({ type = 'continuous', text, size, style, progress }: LoadingComponentProps) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: '64px',
      height: style?.height ? style?.height : size ? setSize(size) : '300px',
      ...style
    }}>
      {
        type === 'continuous' ? <CircularProgress sx={{ color: '#f102ad' }} /> 
        : <CircularProgressWithLabel progress={progress || 10}  />
      }
      { text && <Typography variant="body1">{text}</Typography> }
    </div>
  )
}

export default LoadingComponent