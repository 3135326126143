import React from 'react';
import './styles.css'

interface FilterTagProps {
    label: string;
    onRemove: () => void;
    backgroundColor?: string;
    color?: string;
}

const FilterTag: React.FC<FilterTagProps> = ({ label, onRemove, backgroundColor, color }) => {
    return (
        <div className="filter-tag"
            style={{
                backgroundColor: backgroundColor,
                color: color
            }}        
        >
            <span>{label}</span>
            <button onClick={onRemove} className="remove-btn"
                style={{
                    backgroundColor: color,
                    color: backgroundColor
                }}     
            >×</button>
        </div>
    );
};

export default FilterTag;
