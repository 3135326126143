import { Day, EventData } from "components/schedule/schedule-section"
import { createContext, useEffect, useState } from "react"
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import AuthService from 'utils/services/auth.service'

interface User {
    email: string;
    name: string;
    family_name: string;
    cpf: string;
    vinculo: string;
    genero: string;
    is_admin: boolean;
    is_partner: boolean;
}

type ContextType = {
    scheduleData: EventData[]
    error: boolean
    loading: boolean
    userInfo: User | null
    currentUser: { [key: string]: string } | null
    subscribedEvents: {confirmed: EventData[]; pending: { palestra: EventData; position: number }[];};
    activeDay: Day
    loadScheduleData: () => void
    setScheduleData: Dispatch<SetStateAction<EventData[]>>
    setUserInfo: Dispatch<SetStateAction<User | null>>;
    setLoading: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<boolean>>
    setSubscribedEvents: Dispatch<SetStateAction<{ confirmed: EventData[]; pending: { palestra: EventData; position: number }[] }>>;
    loadUserInfo: () => void
    loadSubscribedEvents: () => void
    updateActiveDay: (day: Day) => void
    updateNextActiveDay: () => void
    updatePreviousActiveDay: () => void
}

interface Props extends Partial<ContextType> {
    children: ReactNode
  }

  export const ScheduleContext = createContext<ContextType>({
    scheduleData: [],
    error: false,
    loading: true,
    currentUser: null,
    userInfo: null, // Começa como null
    subscribedEvents: { confirmed: [], pending: [] },
    activeDay: "todos os dias",
    loadScheduleData: () => undefined,
    setScheduleData: () => undefined,
    setUserInfo: () => undefined, 
    setLoading: () => undefined,
    setError: () => undefined,
    setSubscribedEvents: () => undefined,
    loadUserInfo: () => undefined,
    loadSubscribedEvents: () => undefined,
    updateActiveDay: () => undefined,
    updateNextActiveDay: () => undefined,
    updatePreviousActiveDay: () => undefined,
  });

  const ScheduleContextProvider = ({ children, ...props }: Props) => {
    const [scheduleData, setScheduleData] = useState<EventData[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [userInfo, setUserInfo] = useState<User | null>(null);
    const [subscribedEvents, setSubscribedEvents] = useState<{
        confirmed: EventData[];
        pending: { palestra: EventData; position: number }[]
      }>({
        confirmed: [],
        pending: []
      });
    const [activeDay, setActiveDay] = useState<Day>('todos os dias')

    const days: Day[] = ['todos os dias', 'segunda', 'terça', 'quarta', 'quinta', 'sexta']

    const loadUserInfo = async () => {
        const user = AuthService.getCurrentUser();
        setCurrentUser(user);
    
        if (user) {
          try {
            const response = await AuthService.getUserInfo(user.id_token, user.refresh_token);
            setUserInfo(response.data);
          } catch (error) {
            console.error("Erro ao carregar informações do usuário:", error);
          }
        }
      };
    
    
    const loadScheduleData = async () => {
        await AuthService.getEvents()
            .then(response => {
                if(response.data.length > 0) {
                    return response.data
                }
                throw response
            })
            .then(data => {
                setScheduleData([...data])
            })
            .catch(error => {
                console.error("Error fetching data:", error)
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })

        await loadSubscribedEvents()
    }

    const loadSubscribedEvents = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
          setCurrentUser(user);
          try {
              const result = await AuthService.getSubscribedEvents(user.id_token);
              if (result.data) {
  
                  setSubscribedEvents({
                      confirmed: result.data.confirmed,
                      pending: result.data.pending.map((item: any) => ({
                          palestra: item.palestra,  // Usando o objeto palestra diretamente
                          position: item.position,
                      })),
                  });
              }
          } catch (err) {
              console.error("Error loading subscribed events:", err);
          }
      }
    };
  
  

    const updateActiveDay = (day: Day) => {
        setActiveDay(day)
    }

    const updateNextActiveDay = () => {
        const activeDayIndex = days.findIndex((day: Day) => day === activeDay)
        activeDayIndex < days.length - 1 ? setActiveDay(days[activeDayIndex + 1]) : setActiveDay('todos os dias')
    }

    const updatePreviousActiveDay = () => {
        const activeDayIndex = days.findIndex((day: Day) => day === activeDay)
        activeDayIndex > 0 ? setActiveDay(days[activeDayIndex - 1]) : setActiveDay('sexta')
    }


    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        loadScheduleData()
        // eslint-disable-next-line
        loadUserInfo()
        // eslint-disable-next-line
    }, [])
  
    return (
      <ScheduleContext.Provider
        value={{
            scheduleData,
            loading,
            error,
            currentUser,
            userInfo,
            subscribedEvents,
            activeDay,
            loadScheduleData,
            setScheduleData,
            setLoading,
            setError,
            setSubscribedEvents,
            setUserInfo,
            loadSubscribedEvents,
            loadUserInfo,
            updateActiveDay,
            updateNextActiveDay,
            updatePreviousActiveDay,
          ...props,
        }}
      >
        {children}
      </ScheduleContext.Provider>
    )
  }
  
  export default ScheduleContextProvider