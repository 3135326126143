import './styles.css'
import ScheduleSection from 'components/schedule/schedule-section';
import ScheduleContextProvider from 'utils/context/ScheduleContext'
import HeaderSchedule from 'components/header-schedule';


function SchedulePage() {
    
    return(
        <>
        <ScheduleContextProvider>
            <HeaderSchedule/>
            <div className="Schedule">
                <h1 className="Schedule-title">Cronograma</h1>
            
                <ScheduleSection />
            </div>
            <div className='container-copyright-schedule' style={{minHeight:'0'}}>
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
      </ScheduleContextProvider>
      </>

    );
}

export default SchedulePage;