import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Auth from 'utils/services/auth.service';
import HeaderSchedule from 'components/header-schedule';
import './styles.css';
import { AxiosError } from 'axios';
import EyeIcon from '../../assets/eye-icon.svg';
import EyeSlashIcon from '../../assets/eye-slash-icon.svg';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState({ text: '', color: '' });
    const [showPassword, setShowPassword] = useState(false);
    const query = new URLSearchParams(useLocation().search);
    const confirmationCode = query.get('confirmation_code');
    const username = query.get('username');

    const isPasswordValid = (password: string) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const isLongEnough = password.length >= 8;
        
        return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && isLongEnough;
    };

    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            setMessage({ text: 'As senhas não coincidem.', color: 'red' });
            return;
        }

        if (!isPasswordValid(password)) {
            setMessage({
                text: 'A senha deve conter letra maiúscula, minúscula, número, caractere especial e mais de 8 dígitos.',
                color: 'red',
            });
            return;
        }

        if (confirmationCode && username) {
            try {
                await Auth.resetPassword({
                    confirmation_code: confirmationCode,
                    password: password,
                    username: username,
                });
                setMessage({ text: 'Senha redefinida com sucesso!', color: 'green' });
            } catch (error) {
                const axiosError = error as AxiosError;
                const errorMessage = axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data
                    ? (axiosError.response.data as { message: string }).message
                    : 'Erro ao redefinir a senha. Tente novamente.';
                setMessage({ text: errorMessage, color: 'red' });
            }
        } else {
            setMessage({ text: 'Código de confirmação ou nome de usuário ausente.', color: 'red' });
        }
    };

    return (
        <>
            <HeaderSchedule />
            <div className="body-content forgot-password-container">
                <div className="forgot-container">
                    <h1>Insira uma nova senha para acessar sua conta</h1>
                    <div className="password-field-container">
                        <label htmlFor="password" className="password-label">Nova Senha</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            placeholder="Senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="forgot-input"
                        />
                        <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="show-password-btn"
                            >
                                <img src={showPassword ? EyeSlashIcon : EyeIcon} alt="Mostrar senha" />
                        </button>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="confirmPassword"
                            placeholder="Confirme sua senha"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="forgot-input"
                        />
                    </div>
                    {message.text && (
                        <p style={{ color: message.color, fontWeight: 'bold', fontSize: '15px', padding: 'padding: 0 25px;' }}>
                            {message.text}
                        </p>
                    )}
                    <button onClick={handleResetPassword} className='forgot-container-button'>Recuperar Senha</button>
                </div>
            </div>
            <div className='container-copyright-schedule' style={{minHeight:'0'}}>
                <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
            </div>
        </>
    );
}

export default ResetPassword;
