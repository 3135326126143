import AuthService from 'utils/services/auth.service'
import { useEffect, useState } from "react"
import { useFormik } from 'formik'
import './style.css'
import Card from 'components/schedule/card';
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import LoadingComponent from 'components/loading';
import ImagePicker from 'components/image-picker';

dayjs.locale('pt-br');

interface Sala {
    id: number;
    title: string;
    minutes: number;
    capacity: number;
}

const validate = (values: any) => {
    const errors: any = {};
    if (!values.title) {
        errors.title = 'Campo obrigatório';
    }

    if (!values.description) {
        errors.description = 'Campo obrigatório';
    }

    if (!values.vinculo || values.vinculo === 'empty') {
        errors.vinculo = 'Campo obrigatório';
    }

    if (!values.speaker) {
        errors.speaker = 'Campo obrigatório';
    }

    return errors;
};

function Palestra() {
    const [isLoading, setIsLoading] = useState(false)
    const [subscriptionError, setSubscriptionError] = useState('')

    const [salas, setSalas] = useState<Sala[]>([]);

    const [selectedSala, setSelectedSala] = useState<Sala | undefined>(); // Estado para a sala selecionada
    const [image, setImage] = useState<File | null>(null)
    const [imagePreview, setImagePreview] = useState<string>('')

    const user = AuthService.getCurrentUser()

    useEffect(() => {
        const fetchSalas = async (token: string) => {
            try {
                const response = await axios.get('https://api.secompufpe.com/salas', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setSalas(response.data);
            } catch (error) {
                console.error('Erro ao buscar salas:', error);
            }
        };

        if (user.id_token) {
            fetchSalas(user.id_token);
        } else {
            console.log("Sem usuário!!")
        }


    }, [user.id_token]);

    const formik = useFormik({
        initialValues: {
            duration: 0,
            sala_id: '',
            timestamp: dayjs('2024-12-02T04:00').unix(),
            title: '',
            description: '',
            vinculo: '',
            speaker: '',
            image: '',
        },
        validate,
        onSubmit: (values) => {
            const submitValues = {
                duration: values.duration,
                sala_id: parseInt(values.sala_id),
                timestamp: values.timestamp,
                title: values.title,
                description: values.description,
                vinculo: values.vinculo,
                active: true,
                speaker: values.speaker,
                image: values.image
            };
            doSubscribe(submitValues);
        },
    });

    const updateImage = (content: File, preview?: string) => {
        setImage(content)
        if(preview) setImagePreview(preview)
    }

    const doSubscribe = (values: any) => {
        setIsLoading(true)

        const imageURL = image ? `.${image.type.split('/')[1]}` : values.image

        AuthService.subscribeEvent({ idToken: user.id_token, data: {...values, image: imageURL} })
            .then((res) => {
                if(image) return AuthService.subscribeEventImage({ idToken: user.id_token, eventID: res.data.id, file: image })
                else {
                    formik.resetForm()
                    resetImage()
                }
            })
            .then((res) => {
                if(image && res) {
                    const { url, content_type  } = res?.data
                    return AuthService.uploadEventImage({ url, content_type, file: image })
                }
            })
            .then(() => {
                formik.resetForm()
                resetImage()
            })
            .catch((err) => {
                if (err.response?.data === 'Token JWT inválido ou expirado.') {
                    setSubscriptionError('Login expirado!')
                } else {
                    setSubscriptionError('Ocorreu um erro com o cadastro da palestra. Tente novamente mais tarde!')
                }
                console.log(err)
            })
            .finally(() => setIsLoading(false))
    }

    const resetImage = () => {
        setImage(null)
        setImagePreview('')
    }

    const handleSalaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const salaId = Number(event.target.value);
        const salaSelecionada = salas.find((sala) => sala.id === salaId);
        formik.setFieldValue('duration', salaSelecionada?.minutes)
        setSelectedSala(salaSelecionada); // Atualiza o estado com o objeto completo
        formik.setFieldValue('sala_id', salaId); // Atualiza apenas o ID no formik
    };

    return (
        <div className='subscribeTalkContainer'>
            <div>
                <Card
                    id={0}
                    title={formik.values.title}
                    minutes={formik.values.duration}
                    speaker={formik.values.speaker}
                    description={formik.values.description}
                    vinculo={formik.values.vinculo}
                    image={imagePreview || formik.values.image}
                    timestamp={formik.values.timestamp}
                    registers={0}
                    active={true}
                    sala={selectedSala || { id: 0, title: '', capacity: 50, minutes: 0 }}
                    sala_id={parseInt(formik.values.sala_id)}
                />

            </div>

            <div className='Subscribepalestra'>
                {
                    <form onSubmit={formik.handleSubmit}>
                        <div className='form-group'>
                            <h1>Cadastrar Palestra</h1>
                            <div className='nome-container'>
                                <label>Título *</label>
                                <input
                                    name='title'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title}
                                />
                                {
                                    formik.touched.title && formik.errors.title
                                        ? <span>{formik.errors.title}</span>
                                        : null
                                }
                            </div>

                            <div className='nome-container'>
                                <label>Palestrante(s) *</label>
                                <input
                                    name='speaker'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.speaker}
                                />
                                {
                                    formik.touched.speaker && formik.errors.speaker
                                        ? <span>{formik.errors.speaker}</span>
                                        : null
                                }
                            </div>

                            <div className='description-container'>
                                <label>Descrição *</label>
                                <input
                                    name='description'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                />
                                {
                                    formik.touched.description && formik.errors.description
                                        ? <span>{formik.errors.description}</span>
                                        : null
                                }
                            </div>



                            <div id='drop-down-container'>
                                <div className='drop-down-list'>
                                    <label>Sala *</label>
                                    <select
                                        name="sala_id"
                                        onChange={handleSalaChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.sala_id} // Usa o estado como valor
                                    >
                                        <option value="">Selecione a sala</option>
                                        {salas.map((sala) => (
                                            <option key={sala.id} value={sala.id}>
                                                {sala.title}
                                            </option>
                                        ))}
                                    </select>
                                    {formik.touched.sala_id && formik.errors.sala_id ? (
                                        <span className='error'>{formik.errors.sala_id}</span>
                                    ) : null}
                                </div>
                                
                                <div className='drop-down-list'>
                                    <label>Vinculo UFPE*</label>
                                    <select
                                        name="vinculo"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.vinculo}
                                        required
                                    >
                                        <option value=""></option>
                                        <option value="valcann">Valcann</option>
                                        <option value="beyond">Beyond Co</option>
                                        <option value="neurotech">Neurotech</option>
                                        <option value="eds">EDS</option>
                                        <option value="incognia">Incognia</option>
                                        <option value="vinta">Vinta</option>
                                        <option value="loomi">Loomi</option>
                                        <option value="cin-UFPE">CIn-UFPE</option>
                                        <option value="ufpe">UFPE</option>
                                        <option value="outros">Outros</option>
                                    </select>
                                    {
                                        formik.touched.vinculo && formik.errors.vinculo
                                            ? <span>{formik.errors.vinculo}</span>
                                            : null
                                    }
                                </div>

                                <div className='data'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                        <DateTimePicker
                                            label="Data"
                                            value={dayjs.unix(formik.values.timestamp)}
                                            maxDate={dayjs('2024-12-06T20:00')}
                                            minDate={dayjs('2024-12-02T04:00')}
                                            onChange={(value) => formik.setFieldValue('timestamp', value?.unix())}
                                            sx={{
                                                fieldset: {
                                                    borderColor: '#FDF8F5'
                                                },
                                                label: {
                                                    color: '#FDF8F5'
                                                },
                                                input: {
                                                    color: '#FDF8F5'
                                                },
                                                svg: {
                                                    fill: '#FDF8F5'
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {
                                        formik.touched.timestamp && formik.errors.timestamp
                                            ? <span>{formik.errors.timestamp}</span>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className="photo-select">
                                <div className='nome-container' style={{ width: '33%' }}>
                                    <label>Foto</label>
                                    <input
                                        name='image'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.image}
                                    />
                                    {
                                        formik.touched.image && formik.errors.image
                                            ? <span>{formik.errors.image}</span>
                                            : null
                                    }
                                </div>
                                <p style={{ fontSize: '12px' }}>OU *</p>
                                <div style={{ width: '220px' }}>
                                    <ImagePicker updateImage={updateImage} reset={resetImage} imageURL={imagePreview} />
                                </div>
                            </div>



                            <div className='button-container'>
                                {
                                    isLoading ? <LoadingComponent style={{ margin: 0 }} /> : 
                                    (
                                        <button disabled={!(formik.isValid && formik.dirty) || (!image && !formik.values.image)} type='submit' className='filled-button'>Cadastrar</button>
                                    )
                                }
                                {
                                    <p>{subscriptionError}</p>
                                }
                            </div>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
}

export default Palestra;