import slide1 from './1.jpg';
import slide2 from './2.jpg';
import slide3 from './3.jpg';
import slide4 from './4.jpg';
import slide5 from './5.jpg';
import slide6 from './6.jpg'
import slide7 from './7.jpg'

const images = [slide1, slide2, slide3, slide4, slide5, slide6, slide7];

export default images;