import { Link } from 'react-router-dom'
import logo from '../images/logo.svg'
import './styles.css'
import { ScheduleContext } from 'utils/context/ScheduleContext'
import { useContext } from 'react'
import AuthService from 'utils/services/auth.service'

const HeaderSchedule = ({ backgroundColor = 'black' }: { backgroundColor?: string }) => {
    const { currentUser } = useContext(ScheduleContext)
    
    const handleLogout = () => {
        AuthService.logout();
        window.location.reload();
      };
    return (
        <header style={{ backgroundColor: backgroundColor }}>
            <div className='container-logo'>
                <img style={{height:'3rem'}} src={logo} alt="Logomarca da Secomp" />
            </div>
            <div className='container-options'>
                <ul>
                    { currentUser? (
                        <li className="header-active-link">
                            <Link to={'#schedule-container-id'}>Inscreva-se</Link>
                        </li>
                    ) : 
                        <Link to={'/login'}>
                            Login
                        </Link> 
                    }
                    <li className="header-active-link"><Link to={'/'}> Início </Link></li>

                    { currentUser?(
                        <>  
                            <li className="header-active-link" onClick={handleLogout}>
                                <Link to={'#'}>Sair</Link>
                            </li>
                        </>
                        ) : 
                            null
                        }
                </ul>
            </div>
        </header>
    );
}

export default HeaderSchedule;