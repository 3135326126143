import { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import CloseIcon from 'components/icons/closeIcon'

import './style.css'

interface ImagePickerProps {
  imageURL?: string
  updateImage: (content: File, preview?: string) => void
  reset: () => void
}

const ImagePicker = ({ imageURL, updateImage, reset }: ImagePickerProps) => {
  const [imageError, setImageError] = useState('')
  
  const onDropAccepted = useCallback((acceptedFiles: Array<File>) => {
    const file = new FileReader()
    const fileData = new FormData()
    file.onload = function() {
      updateImage(acceptedFiles[0], file.result as string)
    }
    fileData.append("image", acceptedFiles[0])
    file.readAsDataURL(acceptedFiles[0])
  }, [updateImage])

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          setImageError('File is larger than 10MB.');
        } else if (err.code === "file-invalid-type") {
          setImageError('File type must be png, jpeg or jpg');
        } else {
          setImageError(err.message)
        }
      })
    })
  }, [])

  const { getRootProps, getInputProps, open, isDragActive, isDragReject } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    maxSize: 10000000,
    multiple: false
  })

  return (
    <div {...getRootProps()} style={{ color: 'white' }}>
      <p>Upload logo</p>
      <input {...getInputProps()} />
      <div className="image-picker-container">
        {
          imageURL ? (
            <div style={{ position: 'relative' }}>
              <img src={imageURL as string} alt="imagem do palestrante" style={{ width: '200px' }} />
              <div className="image-picker-container-exclude" onClick={reset}>
                <div
                  className="exclude-box"
                >
                  <CloseIcon fill="white" />
                </div>
              </div>
            </div>
          ) : (
            <div style={{ maxWidth: '180px', fontSize: '12px', paddingLeft: '4px' }}>
              <p>Upload</p>
              <p>
                {
                  isDragActive ? 
                    isDragReject ? 'Esse arquivo não é suportado.' : 'Coloque sua imagem aqui.'
                  : 'Tamanho máximo de arquivo de 10MB. Arquivos suportados: .png e .jpg'
                }
              </p>
              {
                imageError && (
                  <p color="red">{imageError}</p>
                )
              }
            </div>
          )
        }
        <button
          type="button"
          onClick={open}
          style={{
            width: '100%',
            paddingBlock: '4px',
            borderRadius: '50px',
            marginTop: '6px'
          }}
        >{imageURL ? 'Change image' : 'Add image'}</button>
      </div>
    </div>
  )
}

export default ImagePicker