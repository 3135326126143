import { useContext, useEffect, useState } from 'react'
import AuthService from 'utils/services/auth.service'
import { EventData } from '../schedule-section'
import emptyPersonLogo from 'components/images/empty-person.png'
import { useNavigate } from 'react-router-dom'
import { ScheduleContext } from 'utils/context/ScheduleContext'
import { Oval } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert';
import { Day } from '../schedule-section'
import './styles.css'
import './alert.css'

const Card = ({ id, title, minutes,  speaker, vinculo = 'Engenheira de Software na Softex', image = '', description = '', timestamp, sala, sala_id, registers, active}: EventData) => {
    const navigate = useNavigate()

    const typeMapping: { [key: string]: string } = {
        'Anfiteatro': 'Talk',
        'Grad 1': 'Minicurso',
        'Grad 2': 'Minicurso',
        'Grad 3': 'Minicurso',
        'Laboratório de Hardware': 'Minicurso',
        'Pitch': 'Talk',
    };

    const type = typeMapping[sala.title] || 'Outro';

    
    const escapedType = ['Abertura', 'GameDay', 'Encerramento'].includes(type)
    const { currentUser, subscribedEvents, loadSubscribedEvents, userInfo} = useContext(ScheduleContext)


    const isSubscribed = subscribedEvents.confirmed.some((event) => event.id === id);
    const pending = subscribedEvents.pending.some((item) => item.palestra.id === id);
    const [subscribeButtomPos, setSubscribeButtomPos] = useState(-40)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const isEnd = false

    const formatTime = (date: Date) => {
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = date.getMinutes();
        if (minutes > 0) {
            return `${hours}h${("0" + minutes).slice(-2)}`;
        } else {
            return `${hours}h`;
        }
    }


    function converterParaDiaDaSemana(timestamp: number): Day {
        const days: Day[] = ["segunda", "terça", "quarta", "quinta", "sexta"];
    
        const data = new Date(timestamp * 1000); 
    
        const diaDaSemana = data.getDay();
    
        if (diaDaSemana === 0 || diaDaSemana === 6) {
            return "todos os dias";
        }
    
        return days[diaDaSemana - 1]; 
    }

    const dateBegin = new Date(timestamp * 1000);
    const hourBegin = formatTime(dateBegin);
  
    const durationInMillis = (sala.minutes) * 60 * 1000;
    const dateEnd = new Date(timestamp * 1000 + durationInMillis);
    const hourEnd = formatTime(dateEnd);
    const parsedImage = image && image.startsWith('.') ? `https://secomp.cin.ufpe.br/photos/${id}${image}` : image

    const speakerInfo = `${speaker}${vinculo ? `,\n ${vinculo}` : ''}`

    const i_status = registers >= sala.capacity;

    const actionText = isSubscribed ? {
        value: 'Inscrito',
        background: '#2aec54',
        button: {
            value: 'Desinscrever-se',
            background: '#9d0000',
            text: '#FDF8F5'
        }
    } : (pending) ? {
        value: 'Espera',
        background: '#FFE800',
        button: {
            value: 'Sair da fila',
            background: '#9d0000',
            text: '#FDF8F5',
        }
    } : i_status ? {
        value: 'Lotado',
        background: '#FB0000',
        button: {
            value: 'Lista de espera',
            background: '#FFE800',
            text: '#000000',
        }
    } : null;

    useEffect(() => {
        if (!error) return
        setTimeout(() => {
            setError('')
        }, 5000)
    }, [error])


    const handleSubscribe = async (isSubscribed: boolean) => {

        setLoading(true)
        if (isEnd) {
            setError('O Evento foi encerrado!')
            setLoading(false)
            return
        }
        if (currentUser && userInfo) {
            const isAuth = AuthService.authVerify(currentUser.access_token)
            if(!isAuth) {
                AuthService.logout()
                navigate('/login')
            }

            if (isSubscribed || pending) {
                await AuthService.unsubscribe({ idToken: currentUser.id_token, id_palestra: id })
                .catch((err) => {
                    if (err?.response?.data.message === 'Unauthorized') {
                        AuthService.logout()
                        navigate('/login')
                    } else {
                        setError(err.message)
                    }
                }) 
            } else {
                try {
                    const response = await AuthService.subscribe({
                        idToken: currentUser.id_token,
                        id_palestra: id,
                        email: userInfo.email,
                    });
                    console.log("Inscrição realizada:", response.data.message);
                } catch (err:any) {
                    if (err.response?.data?.error) {
                        setError(err.response.data?.error);
                    } else {
                        console.error("Erro na inscrição:", err.response?.data || err.message);
                        setError(err.response?.data || "Erro ao realizar inscrição");
                    }
                }
                    
            }

            loadSubscribedEvents()

        } else {
            navigate('/login')
        }
        setLoading(false)
    }


    const submit = (isSubscribed: boolean, text: string) => {
        if (document) document.body.style.overflowY = 'hidden'
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='alert-container' >
                        <div className='alert-card'>
                            <h1 className='alert-title'>Tem certeza?</h1>
                            <p>Você quer <span className='alert-action'>{text}</span> do evento?</p>
                            <button className='alert-button alert-close' onClick={() => {
                                if (document) document.body.style.overflowY = 'scroll'
                                onClose()
                            }}>Não</button>
                            <button
                            className='alert-button alert-confirm'
                            onClick={() => {
                                if (document) document.body.style.overflowY = 'scroll'
                                handleSubscribe(isSubscribed);
                                onClose()
                            }}
                            >
                            Sim
                            </button>
                        </div>
                    </div>
                )
            },
            title: 'Confirm to submit',
            message: `Você tem certeza que quer ${text}?`,
            buttons: [
              {
                label: 'Sim',
                onClick: () => handleSubscribe(isSubscribed)
              },
              {
                label: 'Não',
                onClick: () => {}
              }
            ],
            closeOnClickOutside: true,
            onClickOutside: () => {},
          });
    }

    return (
        <div className="card" onMouseOver={() => setSubscribeButtomPos(0)} onMouseLeave={() => setSubscribeButtomPos(-40)}>
            {
                speaker || escapedType ? (
                    <div className="card-avatar">
                        <img className={`card-avatar-background ${parsedImage ? '' : 'avatar-empty-image'}`} src={parsedImage ? parsedImage : emptyPersonLogo} alt="foto do palestrante" />
                    </div>
                ) :
                null
            }
            <div className="card-container">
                <div className="card-type-container">
                    <p className={`card-type ${type === 'Minicurso' ? 'minicourse' : type === 'MostraCin' ? 'mostra-cin' : 'talk'}`} style={{ border: escapedType ? '1px solid #460FE1': '', background: escapedType ? '#460FE1': '', color: escapedType ? '#FDF8F5': '' }}>
                        {escapedType ? 'Evento' : type}
                    </p>
                    <p className="card-hour">
                        {converterParaDiaDaSemana(timestamp).charAt(0).toUpperCase() + converterParaDiaDaSemana(timestamp).slice(1)}, {hourBegin}
                        {!escapedType && ` - ${hourEnd}`}
                        <br />
                        {sala.title === 'Laboratório de Hardware' ? 'Lab de Hardware' : sala.title}
                    </p>

                </div>
                <div className="card-title" style={{ display: escapedType ? 'block' : '' }}>
                    {title}
                </div>
                <div className="card-text">
                    <p className="card-speaker-info">
                        {speakerInfo}
                    </p>
                </div>
            </div>
            {
                actionText ? (
                    <div className="card-action" style={{ backgroundColor: actionText.background }}>
                        <h1>{ actionText.value }</h1>
                    </div>
                ) : null
            }
            {
                vinculo !== 'Encerramento' ? 
                    vinculo === 'GameDay' ?
                    (
                        <>
                            <div onClick={() => window.open('/docs/aws.pdf', '_blank')} className="card-subscribe-mobile" style={{ bottom: '40px',backgroundColor: '#a256f8', color: '#FDF8F5'  }}>
                                Requisitos
                            </div>
                            <div onClick={() => window.open('/docs/aws.pdf', '_blank')} className="card-subscribe" style={{  bottom: subscribeButtomPos === 0 ? 40 : -40, backgroundColor: '#a256f8', color: '#FDF8F5'  }}>
                                Requisitos
                            </div>
                            <div onClick={() => handleSubscribe(isSubscribed)} className="card-subscribe-mobile" style={{ backgroundColor: actionText ? actionText.button.background : '#2ACEB1', color: actionText?.button.text  }}>
                                { actionText ? actionText.button.value : 'Inscrever-se' }
                            </div>
                            <div onClick={() => handleSubscribe(isSubscribed)} className="card-subscribe" style={{ bottom: subscribeButtomPos, backgroundColor: actionText ? actionText.button.background : '#2ACEB1', color: actionText?.button.text  }}>
                                { actionText ? actionText.button.value : 'Inscrever-se' }
                            </div>
                            {
                                loading && (
                                    <Oval
                                    height={100}
                                    width={100}
                                    color="#460FE1"
                                    wrapperStyle={{ position: 'absolute', justifyContent: 'center', display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#F102AE"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                            
                                    />
                                )
                            }
                            <div className="error-container" style={{ opacity: error ? '1' : '0' }}>
                                <p>{error}</p>
                            </div>
                        </>
                    )
                    : (
                        <>
                            <div  onClick={() => isSubscribed  ? submit(isSubscribed, actionText?.button.value || '') : handleSubscribe(isSubscribed)}  className="card-subscribe-mobile" style={{ backgroundColor: actionText ? actionText.button.background : '#2ACEB1', color: actionText?.button.text  }}>
                                { actionText ? actionText.button.value : 'Inscrever-se' }
                            </div>
                            <div onClick={() => isSubscribed  ? submit(isSubscribed, actionText?.button.value || '') : handleSubscribe(isSubscribed)} 
                            className="card-subscribe" style={{ bottom: subscribeButtomPos, backgroundColor: actionText ? actionText.button.background : '#2ACEB1', color: actionText?.button.text  }}>
                                { actionText ? actionText.button.value : 'Inscrever-se' }
                            </div>
                            {
                                loading && (
                                    <Oval
                                    height={100}
                                    width={100}
                                    color="#460FE1"
                                    wrapperStyle={{ position: 'absolute', justifyContent: 'center', display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#F102AE"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                            
                                    />
                                )
                            }
                            <div className="error-container" style={{ opacity: error ? '1' : '0', zIndex:'20' }}>
                                <p>{error}</p>
                            </div>
                        </>
                    )
                : null
            }
        </div>
    );
}

export default Card;
