import { useContext, useMemo, useState, TouchEvent, useRef } from 'react'
import Card from 'components/schedule/card'
import { Oval } from 'react-loader-spinner'
import { ScheduleContext } from 'utils/context/ScheduleContext'
import './styles.css'
import FilterModal from './filter-modal'
import FilterTag from './filter-tag'

export type Day =   "todos os dias" |"segunda" | "terça" | "quarta" | "quinta" | "sexta" 

interface ScheduleDayProps {
    day: Day
    isActive: boolean
    updateActiveDay: (day: Day) => void
}

export interface EventData {
    id: number; 
    title: string; 
    description: string; 
    speaker: string; 
    vinculo: string; 
    image: string; 
    timestamp: number; 
    minutes: number; 
    registers: number; 
    active: boolean; 
    sala: {
        id: number; 
        title: string; 
        capacity: number; 
        minutes: number; 
    };
    sala_id: number; 
}


const days: Day[] = ['todos os dias', 'segunda', 'terça', 'quarta', 'quinta', 'sexta']
const eventTypes = ['Minicurso', 'Talk', 'Abertura', 'Encerramento', 'Mostra Cin'] 
const timeFilters = [
    'Antes das 11h',
    'Antes das 13h',
    'Antes das 15h',
    'Antes das 17h',
    'Depois das 9h',
    'Depois das 11h',
    'Depois das 13h',
    'Depois das 15h',
    'Depois das 17h',
];

const typeMapping: { [key: string]: string } = {
    'Anfiteatro': 'Talk',
    'Grad 1': 'Minicurso',
    'Grad 2': 'Minicurso',
    'Grad 3': 'Minicurso',
    'Laboratório de Hardware': 'Minicurso',
    'Pitch': 'Talk',
};

function converterParaDiaDaSemana(timestamp: number): Day {
    const days: Day[] = ["segunda", "terça", "quarta", "quinta", "sexta"];

    const data = new Date(timestamp * 1000); 

    const diaDaSemana = data.getDay();

    if (diaDaSemana === 0 || diaDaSemana === 6) {
        return "todos os dias";
    }

    return days[diaDaSemana - 1]; 
}


const ScheduleDay = ({ day, isActive, updateActiveDay }: ScheduleDayProps) => {
    return (
        <div className={`schedule-section-day${isActive ? '-active': ''} day-option`} onClick={() => updateActiveDay(day)}>
            {day.charAt(0).toUpperCase() + day.slice(1)}
            {
                isActive && <div className='schedule-section-day-highlight'></div>
            }
        </div>
    )
}

const ScheduleSection = () => {
    const { scheduleData,subscribedEvents, loading, error, activeDay, updateActiveDay, updateNextActiveDay, updatePreviousActiveDay } = useContext(ScheduleContext)
    const [startSwapPos, setStartSwapPos] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')  
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [selectedTimeFilters, setSelectedTimeFilters] = useState<string[]>([]);
    const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>([]);
    const [hidePastEvents, setHidePastEvents] = useState(true); // Novo estado para ocultar eventos passados
    const ref = useRef<HTMLDivElement>(null)
    const isSubscribed = subscribedEvents.confirmed
    const pending = subscribedEvents.pending
    

    function handleTouchStart(e: TouchEvent) {
        setStartSwapPos(e.targetTouches[0].clientX);
    }

    function handleTouchEnd(e: TouchEvent<HTMLDivElement>) {
        const endpos = e.changedTouches[0].clientX
        if (startSwapPos - endpos > 150) {
            updateNextActiveDay();
        }
        if (startSwapPos - endpos < -150) {
            updatePreviousActiveDay();
        }
    }

    const handleTypeChange = (type: string) => {
        setSelectedTypes(prev =>
            prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
        );
    };

    const handleTimeFilterChange = (filter: string) => {
        setSelectedTimeFilters(prev =>
            prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter]
        );
    };
    
    const handleStatusFilterChange = (status: string) => {
        setSelectedStatusFilters(prev =>
            prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
        );
    };

    const removeFilter = (type: string, filter: string) => {
        if (type === "type") {
            setSelectedTypes(selectedTypes.filter(t => t !== filter));
        } else if (type === "time") {
            setSelectedTimeFilters(selectedTimeFilters.filter(f => f !== filter));
        } else if (type === "status") {
            setSelectedStatusFilters(selectedStatusFilters.filter(s => s !== filter));
        }
    };

    const filteredData = useMemo(() => {
    
        let events = activeDay === "todos os dias"
            ? scheduleData 
            : scheduleData.filter((event) => converterParaDiaDaSemana(event.timestamp) === activeDay);  

            events = events.map(event => {
                const dateBegin = new Date(event.timestamp);
                const hourBegin = ("0" + dateBegin.getHours()).slice(-2) + (dateBegin.getMinutes() > 0 ? `:${("0" + dateBegin.getMinutes()).slice(-2)}` : '');
                const durationInMillis = (event.minutes || 0) * 60 * 1000;
                const finalTime = new Date(event.timestamp + durationInMillis);
                const hourEnd = ("0" + finalTime.getHours()).slice(-2) + (finalTime.getMinutes() > 0 ? `:${("0" + finalTime.getMinutes()).slice(-2)}` : '');
            
                return {
                    ...event,
                    hourBegin,
                    hourEnd,
                };
            });
        
        if (searchTerm) {
            events = events.filter(event =>
                event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                event.speaker.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (event.description && event.description.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }

        if (selectedTypes.length > 0) {
            events = events.filter(event => selectedTypes.includes(typeMapping[event.sala.title] || 'Outro')); 
        }

        if (selectedTimeFilters.length > 0) {
            events = events.filter(event => {
                return selectedTimeFilters.some(filter => {
                    const eventMinutes = new Date(event.timestamp * 1000).getHours() * 60 + new Date(event.timestamp * 1000).getMinutes();
        
                    if (filter.includes('Antes')) {
                        const filterMinutes = parseInt(filter.match(/\d+/)?.[0] || '0', 10) * 60;
                        return eventMinutes < filterMinutes;
                    }
        
                    if (filter.includes('Depois')) {
                        const filterMinutes = parseInt(filter.match(/\d+/)?.[0] || '0', 10) * 60;
                        return eventMinutes >= filterMinutes;
                    }
        
                    return false;
                });
            });
        }

        if (selectedStatusFilters.length > 0) {
            events = events.filter(event => {
                const isFull = event.sala?.capacity && event.registers >= event.sala.capacity;
                const isUserSubscribed = isSubscribed.some(subscribedEvent => subscribedEvent.id === event.id);
                const isUserPending = pending.some(pendingEvent => pendingEvent.palestra.id === event.id)
                if (selectedStatusFilters.includes("Disponivel") && !isFull) {
                    return true;
                }
                if (selectedStatusFilters.includes("Esgotado") && isFull) {
                    return true;
                }
                if (selectedStatusFilters.includes("Inscrito") && isUserSubscribed) {
                    return true;
                }
                if (selectedStatusFilters.includes("Lista de Espera") && isUserPending) {
                    return true;
                }
                return false;
            });
        }

        if (hidePastEvents) {
            const currentTime = Math.floor(Date.now() / 1000) - 5400;
            events = events.filter((event) => event.timestamp > currentTime);
          }

        return events;
    }, [scheduleData, activeDay, searchTerm, selectedTypes, selectedTimeFilters, selectedStatusFilters, isSubscribed, pending, hidePastEvents]);

    

    return loading ? (
        <Oval
        height={100}
        width={100}
        color="#460FE1"
        wrapperStyle={{ justifyContent: 'center', marginTop: '24px' }}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#F102AE"
        strokeWidth={2}
        strokeWidthSecondary={2}
        />
    ) : error ? (
        <p className="error-message">Erro: Houve algum problema interno com nosso banco de dados. Será resolvido em breve! =(</p>
    ) : (
        
        <div className="schedule-section-container">
            <div className="schedule-section-tab">
                <div className="schedule-section-tab-title">
                    {activeDay === "todos os dias"
                        ? "Todos os Dias"
                        : `${activeDay.charAt(0).toUpperCase() + activeDay.slice(1)}, ${1 + days.findIndex((day: Day) => day === activeDay)} de dezembro`}
                </div>
            </div>
            <div className="schedule-section-tab-days">
                {
                    days.map((day, index) => (
                        <span style={{display: 'flex'}} key={`schedule-day-${day + index}`}>
                            <ScheduleDay day={day} isActive={day === activeDay} updateActiveDay={updateActiveDay} />
                            {
                                index < days.length - 1 && (
                                    <div className="schedule-section-day schedule-section-day-indent">
                                        -
                                    </div>
                                )
                            }
                        </span>
                    ))
                }
            </div>
            <div className="schedule-section-controls">
                <input
                    type="text"
                    placeholder="Pesquise aqui..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
                <FilterModal
                    eventTypes={eventTypes}
                    selectedTypes={selectedTypes}
                    handleTypeChange={handleTypeChange}
                    timeFilters={timeFilters}
                    selectedTimeFilters={selectedTimeFilters}
                    handleTimeFilterChange={handleTimeFilterChange}
                    selectedStatusFilters={selectedStatusFilters}
                    handleStatusFilterChange={handleStatusFilterChange}
                    hidePastEvents={hidePastEvents} // Passando para o FilterModal
                    setHidePastEvents={setHidePastEvents} // Passando para o FilterModal
                />
            </div>

            <div className="selected-filters">
                {selectedTypes.map(type => (
                    <FilterTag
                        key={type}
                        label={type}
                        onRemove={() => removeFilter("type", type)}
                        backgroundColor="#F102AE"
                        color="#FDF8F5"
                    />
                ))}
                {selectedTimeFilters.map(filter => (
                    <FilterTag
                        key={filter}
                        label={filter}
                        onRemove={() => removeFilter("time", filter)}
                        backgroundColor="#EBFF08"
                        color="#000000"
                    />
                ))}
                {selectedStatusFilters.map(status => (
                    <FilterTag
                        key={status}
                        label={status}
                        onRemove={() => removeFilter("status", status)}
                        backgroundColor="#460FE1"
                        color="#FDF8F5"
                    />
                ))}
                {hidePastEvents && (
                <FilterTag
                    key="hidePastEvents"
                    label="Ocultar eventos antigos"
                    onRemove={() => setHidePastEvents(false)}
                    backgroundColor="#AAAAAA"
                    color="#FFFFFF"
                />
                )}
            </div>

            <div 
                className="schedule-section-grid"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                style={{ position: 'relative' }}
                ref={ref}
            >
                {
                    filteredData.length > 0 ? 
                        filteredData.map((event, index) => (
                            <Card {...event} key={`schedule-card-${event.timestamp}-${index}`} />
                        ))
                    : (
                        <div className="empty-box">
                            <img width={200} src="https://visualpharm.com/assets/981/Empty%20Box-595b40b65ba036ed117d43e8.svg" alt="caixa vazia" />
                            <p className="empty-schedule">
                                Nenhum evento encontrado!
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ScheduleSection